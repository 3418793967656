<template>
  <div class="qrcode-page flex-center" @click="closeModal">
    <div class="modal-content" id="modal-content">
      <div class="top">
        <div class="close-btn flex-center"><i class="fa fa-times"></i></div>
      </div>

      <div class="directory">
          <b>Directory</b>
      </div>

      <div class="count flex">
          <div></div>
          <div class="selected-count">{{ persons.filter(person => person.selected === true ).length }} of {{ persons.length }}</div>
          <div class="flex" style="align-items: center;">
            <b>All</b>
            <div class="checkbox" style="margin-top: 10px; margin-left: 5px">
              <input type="checkbox" id="checkbox" @change="selectAll" v-model="all"/>
              <label for="checkbox"></label>
            </div>
          </div>
      </div>

      <div class="body scroll">
        <div class="persons">
          <div class="person flex" v-for="(person, index) in persons" :key="index">
              <div class="flex">
                <div class="image-holder">
                    <img :src="person.img">
                </div>
                <div>
                    <p class="name">{{ person.name }}</p>
                    <p class="tag">@{{ person.tagName }}</p>
                </div>
              </div>

              <div class="checkbox">
                <input type="checkbox" :id="`checkbox${index}`" @change="toggleCheck(index)" v-model="person.selected"/>
                <label :for="`checkbox${index}`"></label>
              </div>
          </div>
      </div>

      <div class="btn">
        <Button @click="$router.push('/orders/checkout')" :style="{ color: '#fff', backgroundColor: '#159661', width: '150px', borderRadius: '50px', padding: '10px' }" text="Proceed"/>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button.vue'

export default {
  components: {
    Button
  },
  data(){
    return {
      classes: ['qrcode-page flex-center', 'close-btn flex-center', 'fa fa-times'],
      formData: {
        image: ''
      },
      img: "https://images.pexels.com/photos/1043474/pexels-photo-1043474.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
      persons: [
          { name: "Linus Trovalds", tagName: "linustrovald", img: "https://images.pexels.com/photos/1043474/pexels-photo-1043474.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500", selected: false },
          { name: "Hang Longstead", tagName: "nonsogodfrey", img: "https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500", selected: false },
          { name: "Dwaine Johnson", tagName: "nonsogodfrey", img: "https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8bWVufGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60", selected: false },
          { name: "Nonso Godfrey", tagName: "nonsogodfrey", img: "https://images.pexels.com/photos/1043474/pexels-photo-1043474.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500", selected: false },
          { name: "Linus Trovalds", tagName: "linustrovald", img: "https://images.pexels.com/photos/1043474/pexels-photo-1043474.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500", selected: false },
          { name: "Hang Longstead", tagName: "nonsogodfrey", img: "https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500", selected: false },
          { name: "Dwaine Johnson", tagName: "nonsogodfrey", img: "https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8bWVufGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60", selected: false },
          { name: "Nonso Godfrey", tagName: "nonsogodfrey", img: "https://images.pexels.com/photos/1043474/pexels-photo-1043474.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500", selected: false },
      ],
      all: false
    }
  },
  methods: {
    closeModal(e){
      if(this.classes.includes(e.target.className)){
        this.$router.push('/orders')
      }
    },
    selectAll(){
        if(this.all === true) {
            this.persons.forEach(person => person.selected = true)
            // this.all = true
        } else {
            this.persons.forEach(person => person.selected = false)
            // this.all = false
        }
    },
    toggleCheck(index) {
        this.persons(index).selected = !this.persons(index).selected
    }
  }
}
</script>

<style scoped>
    .qrcode-page {
      position: fixed;
      z-index: 1;
      top: 0;
      left: 0;
      height: 100%;
      overflow: auto;
      width: 100%;
      background: rgba(0,0,0,0.5);
      padding: 10px 0px;
    }

    .modal-content {
      height: 100%;
      width: 500px;
      background: #fff;
      padding: 20px;
      box-shadow: 0 0 6px 3px #00000035;
      /* margin-top: 10rem; */
    }

    .top {
      text-align: right;
      display: flex;
      justify-content: right;
    }

    .close-btn {
      height: 20px;
      width: 20px;
      font-size: 10px;
      border-radius: 100px;
      text-align: center;
      cursor: pointer;
      text-align: right;
      background-color: rgba(0, 0, 0, 0.152);
    }

    .close-btn:hover {
      background-color: rgba(0, 0, 0, 0.042);
    }

    .directory, .count, .persons {
        padding: 0 22px;
    }

    .flex {
        align-items: center;
    }

    .image-holder {
        width: 50px;
        height: 50px;
        border-radius: 100px;
        overflow: hidden;
        margin-right: 10px;
    }

    .body {
        flex-direction: column;
        height: 89%;
        /* padding-top: 17rem;
        padding-bottom: 3rem; */
        overflow: auto;
    }

    .body h3 {
        margin-bottom: 20px;
    }

    .body > div {
        margin-bottom: 20px;
    }

    .persons > div {
        margin-bottom: 30px;
    }

    .name {
        font-weight: 600;
        font-size: 14px;
        line-height: .7em;
    }

    .tag {
        font-size: 13px;
        font-weight: 500;
        color: #979797;
    }

    img {
        width: 100%;
    }
    
    .count {
        margin-bottom: 10px;
    }

    .btn {
        border-top: 1px solid #ccc;
        text-align: center;
        padding: 20px;
        width: 80%;
        margin: 0 auto;
    }

    .checkbox {
        position: relative;
    }

    .checkbox label {
        background-color: #fff;
        border: 1px solid #979797;
        cursor: pointer;
        height: 15px;
        left: 0;
        position: absolute;
        top: 0;
        width: 15px;
        border-radius: 3px;
    }

    .checkbox label:after {
        border: 2px solid #505050;
        border-top: none;
        border-right: none;
        content: "";
        height: 3px;
        left: 2px;
        opacity: 0;
        position: absolute;
        top: 3px;
        transform: rotate(-45deg);
        width: 7px;
    }

    .checkbox input[type="checkbox"] {
        visibility: hidden;
    }

    .checkbox input[type="checkbox"]:checked + label {
        background-color: transparent;
    }

    .checkbox input[type="checkbox"]:checked + label:after {
        opacity: 1;
    }
</style>